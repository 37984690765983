<style lang="scss">
.el-cascader-node__label {
    max-width: 200px;
}

.treat-panel {
    padding-top: 10px;

    &__item {
        display: inline-block;

        & {
            margin-left: 20px;
        }
    }

    .el-select {
        width: 284px;
    }
}

.txt-btn {
    color: #666;
    font-size: 14px;
    margin-right: 10px;

    &:hover {
        color: #5171DA;
    }
}
</style>
<template>
    <div ref="panel" class="scroll-div" style="padding-top: 0;">
        <ts-table v-if="loaded" ref="table" :cols="cols" :data="list" :loading="loading"
            :style="{ height: realHeight + 'px' }" full :error-code="error" hide-page hide-detail zero-to-empty>
            <template #left>
                <ts-table-operate v-if="cols.length > 0" hide-search show-export @leadingOut="exportInfo()"
                    style="margin: 0 20px;align-items: center;">
                    <div>（销售额单位：{{ $variable.unit }}）</div>
                </ts-table-operate>
            </template>
        </ts-table>


        <div class="total-title__line"></div>
        <div class="total-title" id="drug">
            <!--大类药品TOP20-->
            分类药品TOP20
            <div class="total-title__right">
                <el-button class="txt-btn" type="text" icon="el-icon-document" @click="leadingTwo">导出</el-button>
                （销售额单位：{{ $variable.unit }}）
            </div>

        </div>
        <div class="treat-panel">
            <div class="treat-panel__item">大类：
                <el-select v-model="treatBig.model" filterable :loading="treatBig.loading" value-key="id"
                    placeholder="请选择大类" @change="treatBigChange">
                    <el-option v-for="item in treatBig.list" :key="item.id" :value="item"
                        :label="item.treatCategoryName"></el-option>
                </el-select>
            </div>
            <div class="treat-panel__item" v-show="treatBig.model.id">亚类：
                <el-select v-model="treatSmall.model" filterable :loading="treatSmall.loading" value-key="id" clearable
                    placeholder="请选择亚类" @change="treatSmallChange" @clear="treatBigChange">
                    <el-option v-for="item in treatSmall.list" :key="item.id" :value="item"
                        :label="item.treatCategoryName"></el-option>
                </el-select>
            </div>
            <div class="treat-panel__item" v-show="treatSmall.model.id">三类：
                <el-select v-model="treatThree.model" filterable :loading="treatThree.loading" value-key="id" clearable
                    placeholder="请选择三类" @change="treatThreeChange" @clear="treatSmallChange">
                    <el-option v-for="item in treatThree.list" :key="item.id" :value="item"
                        :label="item.treatCategoryName"></el-option>
                </el-select>
            </div>
            <div class="treat-panel__item" v-show="treatThree.model.id">四类：
                <el-select v-model="treatFour.model" filterable :loading="treatFour.loading" value-key="id" clearable
                    placeholder="请选择四类" @change="treatFourChange" @clear="treatThreeChange">
                    <el-option v-for="item in treatFour.list" :key="item.id" :value="item"
                        :label="item.treatCategoryName"></el-option>
                </el-select>
            </div>
        </div>
        <ts-table v-if="loaded" :cols="canvas.cols" :data="canvas.list" :loading="canvas.loading"
            :style="{ height: realHeight - 30 + 'px' }" full zero-to-empty :error-code="canvas.code" hide-page
            hide-detail :summaryMethod="summaryFun"></ts-table>
    </div>
</template>

<script>
import myMixin from '@/util/mixin'
export default {
    mixins: [myMixin],
    props: ['visitId'],
    data() {
        return {
            stop:true,

            treatBigMap: {},
            loading: false,
            list: [],
            cols: this.$help.generalCols([
                {
                    fieldName: 'treatBigName',
                    name: '药品大类名称',
                    listQueryView: '是',
                    width: 150,
                },
                ...this.$variable.years.map(item => {
                    return this.$help.joinObj({
                        fieldName: 'amount' + item.value,
                        name: item.value + '年',
                        listOrderView: true,
                        width: 100,
                        listClass: 'tc'
                    })
                }),
                {
                    fieldName: 'rankIndex',
                    name: `${this.$variable.lastYear.value}大类排名`,
                    listView: '是',
                    width: 120,
                    listOrderView: true,
                    listClass: 'tc'
                },
            ]),
            active: '',
            error: '',
            canvas: {
                show: false,
                message: 200,
                loading: false,



                list: [],
                code: 200,
                cols: this.$help.generalCols([
                    { fieldName: 'comName', name: '通用名', width: 250 },
                    ...this.$variable.years.map(item => {
                        return {
                            fieldName: 'amount' + item.value,
                            name: item.value + '年',
                            listOrderView: true,
                            width: 100,
                            listClass: 'tc'
                        }
                    }),
                ]),
            },

            treatBig: {
                model: {},
                loading: false,
                list: [],
            },
            treatSmall: {
                model: {},
                loading: false,
                list: [],
            },
            treatThree: {
                model: {},
                loading: false,
                list: [],
            },
            treatFour: {
                model: {},
                loading: false,
                list: [],
            },

            loaded: false,
            realHeight: 100,
            query: [],
            searchObj:{},
        }
    },
    created() {
        if (JSON.stringify(this.$route.query) !== '{}') {
            this.query = this.$help.deepClone(JSON.parse(this.$route.query.odd))
            this.$nextTick(() => {
                document.getElementById('drug').scrollIntoView();
            })
        }
        this.loading = true
        this.$api.post('saleTreatBig/saleYearTreatBig/drugsSaleYearTreatBigList', undefined, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
            .then(res => {
                if (res.code === this.$variable.successCode) {
                    this.list = res.data
                } else {
                    this.error = res.code
                    this.canvas.code = res.code
                }
            })
            .finally(() => {
                this.loading = false
            })

        this.loadCategory('0', this.treatBig, list => {
            if (list.length > 0) {
                this.treatBig.model = list[0]
                this.treatBigChange()
                this.showProduct()
            }
        })
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 50
        this.loaded = true
    },
    methods: {
        exportInfo(txt = '确定导出吗', query = {}) {
            this.$store.commit('confirm', {
                content: txt,
                callback: (next, back) => {
                    this.$api.post('saleTreatBig/saleYearTreatBig/exportAimDrugsSaleYearTreatBig', query)
                        .then(res => {
                            if (!(res.success || res.code === 'N0001')) {
                                next()
                                this.$store.commit('error', res.message)
                            } else if (res.code === 'N0001') {
                                next()
                                this.exportInfo(res.message, { model: true })
                            } else {
                                this.$help.getExportStatus(res.data.id, data => {
                                    if (data === true) {
                                        next()
                                        this.$store.commit('success', '导出成功')
                                    } else {
                                        back()
                                        this.$store.commit('error', data)
                                    }
                                })
                            }
                        })
                        .catch(back)
                },
            })
        },
        leadingTwo(){
            const search = {
                params:this.searchObj
            }
            this.$help.exportInfo('确定导出吗', "treatBig/saleYearTreatBig/exportSumAmountGroupByComName", search)
        },

        showProduct() {
            this.canvas.loading = true
            let str = ''
            let model = ''
            this.searchObj = {}
            if (this.treatFour.model.treatCategoryCode) {
                str = 'treatFourCode'
                model = this.treatFour.model.treatCategoryCode
                this.searchObj.treatFourCode = model
            } else if (this.treatThree.model.treatCategoryCode) {
                str = 'treatThreeCode'
                model = this.treatThree.model.treatCategoryCode
                this.searchObj.treatThreeCode = model
            } else if (this.treatSmall.model.treatCategoryCode) {
                str = 'treatSmallCode'
                model = this.treatSmall.model.treatCategoryCode
                this.searchObj.treatSmallCode = model
            } else if (this.treatBig.model.treatCategoryCode) {
                str = 'treatBigCode'
                model = this.treatBig.model.treatCategoryCode
                this.searchObj.treatBigCode = model
            }
            this.$api.post('saleTreatBig/saleYearTreatBig/sumAmountGroupByComName', {
                params: {
                    [str]: model
                }
            })
                .then(res => {
                    if (res.data) {
                        this.canvas.list = res.data
                        this.sumData = res.dataMap || {}
                    }
                })
                .finally(() => {
                    this.canvas.loading = false
                })
        },

        // 加载类型数据
        loadCategory(parentId, model, cb) {
            model.loading = true
            this.$api.post('ba/baTreatCategory/queryBaTreatCategorySelect', { params: { parentId } })
                .then(res => {
                    model.list = res.data
                    if (this.query && this.query.length != 0) {
                        if (parentId == '0') {
                            cb && cb([this.query[0]])
                        } else {
                            this.query.forEach((item, index) => {
                                if (item.id === parentId) {
                                    cb && cb([this.query[index + 1]])
                                    if (index + 1 === this.query.length) {
                                        return false
                                    }
                                    if (index === 0) {
                                        this.treatSmall.model = this.query[index + 1]
                                        this.treatSmallChange()
                                    } else if (index === 1) {
                                        this.treatThree.model = this.query[index + 1]
                                        this.treatThreeChange()
                                    } else if (index === 2) {
                                        this.treatFour.model = this.query[index + 1]
                                        this.treatFourChange()
                                    }
                                }
                            })
                        }
                    } else {
                        cb && cb(res.data)
                    }
                })
                .finally(() => {
                    model.loading = false
                })
        },
        treatBigChange() {
            this.treatSmall.model = {}
            this.treatThree.model = {}
            this.treatFour.model = {}
            this.loadCategory(this.treatBig.model.id, this.treatSmall)
            this.showProduct()
        },
        treatSmallChange() {
            this.treatThree.model = {}
            this.treatFour.model = {}
            this.loadCategory(this.treatSmall.model.id, this.treatThree)
            this.showProduct()
        },
        treatThreeChange() {
            this.treatFour.model = {}
            this.loadCategory(this.treatThree.model.id, this.treatFour)
            this.showProduct()
        },
        treatFourChange() {
            this.showProduct()
        },
    }
}
</script>